import React, { useState, useEffect } from "react";
import { FilterBox, FilterDiv } from "./TrendsStyle";
import axios from "axios";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

function Filter() {
  const { id } = useParams();
  const [isSelected, setIsSelected] = useState(null);
  const [datas, setDatas] = useState([]);
  useEffect(() => {
    getDatas();
  }, [id]);
  const getDatas = async () => {
    await axios
      .get(
        "https://port-0-back-deploy-eu1k2lllf8s0bx.sel3.cloudtype.app/trend/ "
      )
      .then((response) => {
        setDatas(response.data);
        console.log("성공");
        console.log(datas);
      })
      .catch((error) => {
        console.log("트렌드 불러오기 실패", error.message);
      });
  };
  return (
    <FilterBox>
      {datas &&
        datas.map((item, index) => {
          return (
            <Link to={`/trend/${index + 1}`}>
              <FilterDiv
                style={{
                  backgroundColor: id && id == index + 1 ? "white" : "",
                  color: id && id == index + 1 ? "#3a42bf" : "",
                }}
                id={index + 1}
              >
                {item}
              </FilterDiv>
            </Link>
          );
        })}
    </FilterBox>
  );
}

export default Filter;
