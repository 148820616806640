import React from "react";
import CheckPC from "../components/Check/CheckPC";

function CheckPage() {
  return (
    <>
      <CheckPC />
    </>
  );
}

export default CheckPage;
